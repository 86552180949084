<template>
    <v-container bg fill-height grid-list-md text-center class="mt-16">
         <v-layout wrap align-center>
           <v-flex justify-center class="mt-16">
             <div>
                <v-progress-circular
                :size="50"
                indeterminate
                color="primary"
                ></v-progress-circular>
                <h1 class="mt-5">{{ loadingText }}</h1>
             </div>
           </v-flex>
         </v-layout>
     </v-container>
 </template>
<script>
export default {
  name: 'loading',
  data () {
    return {
      loadingText: this.$t('loading.loadingLabel'),
    }
  },
}
</script>