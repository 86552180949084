<template>
  <v-row>
    <v-col>
      <FilterInformation
      :date="dateDatePicker"
      :loadingProp="loading"
      @trigger-action="submitData"
      />
      <NotificationDialog ref="dialog" />
      <LoadingView v-if="loading === true"/>
    </v-col>
  </v-row>
</template>
<script>
import FilterInformation from '../components/filter/Filter'
import NotificationDialog from './NotificationDialog'
import LoadingView from './LoadingView'
import { EXTENSION_API_BASE_URL } from '@/config/environment'
import axios from 'axios'
import router from '../router'
export default {
  name: 'points',
  components: {
    FilterInformation,
    LoadingView,
    NotificationDialog,
  },
  data () {
    return {
      dataSelected: {
        email: '',
        startDate: '',
        endDate: '',
      },
      loading: false,
      tittleNotification: this.$t('notification.notificationLabel'),
      notificationMessage: '',
      notificationOptions: {
        color: '',
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    }
  },
  computed: {
    getTokenValue () {
      return this.$store.state.state.token
    },
    dateDatePicker () {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
  },
  methods: {
    getData (data) {
      this.dataSelected.email = data.email
      this.dataSelected.startDate = data.startDate
      this.dataSelected.endDate = data.endDate
    },
    async submitData (params) {
      this.loading = true
      this.getData(params)
      await this.getDataApi()
    },
    getDataApi () {
      const token = this.getTokenValue
      const filterParam = this.dataSelected
      if(!this.validateEmail(filterParam.email)) {
        this.loading = false
        return
      }

      let arrayValue = filterParam.startDate.split('-')
      filterParam.startDate = `${ arrayValue[2] }-${ arrayValue[1] }-${ arrayValue[0] }`
      arrayValue = filterParam.endDate.split('-')
      filterParam.endDate = `${ arrayValue[2] }-${ arrayValue[1] }-${ arrayValue[0] }`
      const config = {
        headers: { authorization: `Bearer ${ token ? token : '' }` },
        params: filterParam,
      }
      return new Promise((resolve, reject) => {
        axios.get(`${ EXTENSION_API_BASE_URL }/ticketsales`, config)
          .then((res) => {
            this.loading = false
            this.notificationOptions.color = 'light-blue darken-1'
            this.notificationMessage = this.$t('notification.notificationSuccess')
            this.notificationDialog()
            resolve()
          })
          .catch((error) => {
            this.loading = false
            if (error.response && error.response.status == 401) {
              router.push('/unauthorized')
            }
            else{
              this.notificationOptions.color = 'red darken-1'
              this.notificationMessage = this.$t('notification.notificationError')
              this.notificationDialog()
            }
            resolve()
          })
      })
    },
    async notificationDialog () {
      this.$refs.dialog.open(this.tittleNotification, this.notificationMessage, this.notificationOptions)
    },
    validateEmail (email) {
      this.notificationOptions.color = 'red darken-1'
      if(!email) {
        this.notificationMessage = this.$t('input.required')
        this.notificationDialog()
        return false
      }
      const re = /\S+@\S+\.\S+/
      if(!re.test(email)) {
        this.notificationMessage = this.$t('input.emailInvalid')
        this.notificationDialog()
        return false
      }
      return true
    },
  },
}
</script>
<style lang="scss">
.v-text-field.currency .v-text-field__slot>input {
  text-align: right;
  margin-right: 5px;
  font-size: 30px;
  max-height: 40px;
}

.alert__no-border {
  border-top: none !important;
}
</style>
